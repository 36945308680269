<template>
  <div class="list_warp">
    <div class="right">
      <TopCard ref="TopCardRef"
               text="整卷测试"
               :showBack="true" />
      <div class="input_wrap">
        <el-input placeholder="请输入内容"
                  @keyup.enter.native="initList"
                  v-model="keywords">
          <i slot="suffix"
             @click="initList"
             class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="list">
        <div class="warp">
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="10">
              <div class="paper_name">
                {{row.source_name}}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="paper_source">
                {{row.district_name}}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="paper_source">
                难度系数：{{row.difficulty}}
              </div>
            </el-col>
            <el-col :span="7">
              <div class="paper_source">
                {{row.user_done}}</div>
            </el-col>
            <el-col :span="3">
              <div class="btn_wrap">
                <!-- <div class="btn">
                  打印试卷
                </div> -->
                <div class="btn"
                     @click="doPaper(row)">
                  开始测试
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getWholePaperList } from '@/api/wholepaper.js'

export default {
  data () {
    return {
      tableData: [],
      keywords: ''
    }
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      let params = JSON.parse(this.$route.query.form)
      params.keywords = this.keywords
      const { data } = await getWholePaperList(params)
      this.tableData = data.list
    },
    doPaper (row) {
      let params = {
        source_id: row.source_id,
        paper_year: row.paper_year,
        type: 3,
      }
      this.$router.push('/doPaper?type=6&form=' + JSON.stringify(params))
    }
  }
}
</script>

<style lang="scss" scoped>
.list_warp {
  overflow: hidden;
  display: flex;
  .right {
    width: 100%;
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        display: flex;
        align-items: center;
        padding: 20rem;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba($color: #5ba8e8, $alpha: 0.1);
        }
      }
      div {
        // height: 80rem;
        // line-height: 80rem;
      }
      .paper_name {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        padding-left: 50rem;
        padding-right: 10rem;
      }
      .paper_source {
        font-weight: 500;
        font-size: 20rem;
        color: #333333;
      }
      .btn_wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
          width: 120rem;
          height: 44rem;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20rem;
          margin-top: 20rem;
          cursor: pointer;
          &:nth-child(2) {
            color: #ffffff;
            background: #2196f3;
          }
          &:nth-child(1) {
            color: #2196f3;
            background: #e6f0ff;
          }
        }
      }
    }
  }
  .input_wrap {
    width: 400rem;
    position: absolute;
    top: 28rem;
    right: 44rem;
    ::v-deep .el-input--medium .el-input__inner {
      border-radius: 20rem;
      height: 44rem !important;
      line-height: 44rem;
      font-size: 18rem;
    }
    ::v-deep .el-input--medium .el-input__icon {
      margin-right: 20rem;
      line-height: 44rem;
      font-size: 22rem;
      cursor: pointer;
    }
  }
}
</style>